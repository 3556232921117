import { Formik } from 'formik';
import { useRef } from 'react';
import { useMutation, useQuery } from 'react-query';
import { RetiramoApi, WithdrawOrderParams } from '../../services/api';
import { BoxIcon, EmailIcon } from '../shared/icons';
import Timer, { TimerRef } from '../shared/timer/timer.component';
import * as Abbiamo from './form-page.styles';
import { flatAddress, onlyNumbers, translateWithdrawOrderError } from './form-page.utils';
import { FormValidationSchema } from './form.schema';
import { DateTime } from 'luxon';
import * as FormComponents from '../shared/form.styles';
import * as Common from '../shared/common.styles';
import FormField from '../shared/form-field.component';
import { useSearchParam } from 'react-use';
import { useNavigate } from 'react-router-dom';

const initialValues = {
  code: '',
  name: '',
  document_number: '',
  description: ''
};

const TIME_TO_WAIT = 30000;

// const masks = {
//   cpf: '999.999.999-99',
//   cnpj: '99.999.999/9999-99'
// };

const FormPage = () => {
  const sid = useSearchParam('sid');
  const oid = useSearchParam('oid');
  const navigate = useNavigate();
  const { data: order, isSuccess: hasSuccessfullyFetchedOrder } = useQuery(
    ['ORDER', oid],
    () => RetiramoApi.getOrder(oid),
    {
      onError: () => {
        navigate(`/404${sid ? `?sid=${sid}` : ''}`);
      }
    }
  );

  const {
    mutate: withdrawOrder,
    error,
    isError: withdrawOrderHasFailed,
    isSuccess: withdrawOrderHasSucceeded,
    isLoading: isWithdrawingOrder,
    data: withdrawOrderData
  } = useMutation({
    mutationFn: (params: WithdrawOrderParams) => RetiramoApi.withdrawOrder(oid, params)
  });
  const formErrorMessage = translateWithdrawOrderError(error);
  const handleSubmit = async (values: typeof initialValues) => {
    withdrawOrder({
      ...values,
      code: String(values.code),
      document_number: String(onlyNumbers(values.document_number))
    });
  };
  const timerRef = useRef<TimerRef>(null);

  const { mutate: resendToken, isLoading: isResendingToken } = useMutation(async () => {
    await RetiramoApi.resendToken(oid);
    timerRef.current?.startTimer(TIME_TO_WAIT);
  });

  const Confirmation = () => (
    <>
      <Abbiamo.ConfirmationContainer>
        <Abbiamo.ConfirmationTitle>
          O pedido {withdrawOrderData.order_number} pode ser retirado!
        </Abbiamo.ConfirmationTitle>
        <Abbiamo.ConfirmationSection>
          <Abbiamo.ConfirmationRow>
            <Abbiamo.ConfirmationLabel>Nome do Comprador:</Abbiamo.ConfirmationLabel>
            <Abbiamo.ConfirmationValue>{withdrawOrderData.customer.name}</Abbiamo.ConfirmationValue>
          </Abbiamo.ConfirmationRow>
          {withdrawOrderData.customer.document_number && (
            <Abbiamo.ConfirmationRow>
              <Abbiamo.ConfirmationLabel>Documento:</Abbiamo.ConfirmationLabel>
              <Abbiamo.ConfirmationValue>
                {withdrawOrderData.customer.document_number}
              </Abbiamo.ConfirmationValue>
            </Abbiamo.ConfirmationRow>
          )}
          <Abbiamo.ConfirmationRow>
            <Abbiamo.ConfirmationLabel>Pedido criado em:</Abbiamo.ConfirmationLabel>
            <Abbiamo.ConfirmationValue>
              {DateTime.fromISO(withdrawOrderData.date).toFormat('dd/MM/yyyy HH:mm')}
            </Abbiamo.ConfirmationValue>
          </Abbiamo.ConfirmationRow>
        </Abbiamo.ConfirmationSection>
        <Abbiamo.ConfirmationSection>
          <Abbiamo.ConfirmationRow>
            <Abbiamo.ConfirmationLabel>Nome do Retirante:</Abbiamo.ConfirmationLabel>
            <Abbiamo.ConfirmationValue>{withdrawOrderData.receiver.name}</Abbiamo.ConfirmationValue>
          </Abbiamo.ConfirmationRow>
          <Abbiamo.ConfirmationRow>
            <Abbiamo.ConfirmationLabel>Documento:</Abbiamo.ConfirmationLabel>
            <Abbiamo.ConfirmationValue>
              {withdrawOrderData.receiver.document_number}
            </Abbiamo.ConfirmationValue>
          </Abbiamo.ConfirmationRow>
          {withdrawOrderData.receiver.description && (
            <Abbiamo.ConfirmationRow>
              <Abbiamo.ConfirmationLabel>Descrição:</Abbiamo.ConfirmationLabel>
              <Abbiamo.ConfirmationValue>
                {withdrawOrderData.receiver.description}
              </Abbiamo.ConfirmationValue>
            </Abbiamo.ConfirmationRow>
          )}
          <Abbiamo.ConfirmationRow>
            <Abbiamo.ConfirmationLabel>Retirado (Local) em:</Abbiamo.ConfirmationLabel>
            <Abbiamo.ConfirmationValue>
              {flatAddress(withdrawOrderData.address)}
            </Abbiamo.ConfirmationValue>
          </Abbiamo.ConfirmationRow>
          <Abbiamo.ConfirmationRow>
            <Abbiamo.ConfirmationLabel>Retirado (Horário) em:</Abbiamo.ConfirmationLabel>
            <Abbiamo.ConfirmationValue>
              {DateTime.fromISO(withdrawOrderData.filled_at).toFormat('dd/MM/yyyy HH:mm')}
            </Abbiamo.ConfirmationValue>
          </Abbiamo.ConfirmationRow>
        </Abbiamo.ConfirmationSection>
      </Abbiamo.ConfirmationContainer>
    </>
  );

  const FormComponent = (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={FormValidationSchema(order)}>
      {() => (
        <FormComponents.FormContainer>
          <FormField
            type="tel"
            name="code"
            label="Código de Confirmação"
            required
            mask={{ mask: '999999', maskChar: '' }}
          />
          <Timer ref={timerRef}>
            {({ prettyTime, time, isFinished }) => (
              <>
                <FormComponents.FormButton
                  disabled={isResendingToken || time > 0}
                  onClick={() => resendToken()}
                  type="button">
                  <FormComponents.FormButtonIcon>
                    <EmailIcon />
                  </FormComponents.FormButtonIcon>
                  {isResendingToken ? 'Reenviando Código...' : 'Reenviar Código'}
                </FormComponents.FormButton>
                {isFinished ? (
                  ''
                ) : (
                  <Abbiamo.WaitTime>Reenviar o código novamente em {prettyTime}s</Abbiamo.WaitTime>
                )}
              </>
            )}
          </Timer>
          <FormField name="name" label="Nome" required />
          <FormField
            // mask={{
            //   mask: onlyNumbers(values.document_number).length > 11 ? masks.cnpj : masks.cpf,
            //   maskChar: ''
            // }}
            name="document_number"
            label="Documento"
            type={'tel'}
            required
          />
          <FormField
            asComponent="textarea"
            name="description"
            label="Descrição"
            placeholder="Ex: Pai"
          />
          {withdrawOrderHasFailed && (
            <FormComponents.FormErrorMessage>{formErrorMessage}</FormComponents.FormErrorMessage>
          )}
          <FormComponents.FormButton disabled={isWithdrawingOrder} type="submit">
            <FormComponents.FormButtonIcon>
              <BoxIcon />
            </FormComponents.FormButtonIcon>
            {isWithdrawingOrder ? 'Enviando...' : 'Enviar'}
          </FormComponents.FormButton>
        </FormComponents.FormContainer>
      )}
    </Formik>
  );

  return (
    <Abbiamo.PageContainer>
      <FormComponents.FormLogo />
      <FormComponents.FormTitle>CLIQUE E RETIRE</FormComponents.FormTitle>
      {hasSuccessfullyFetchedOrder && (
        <FormComponents.Order>
          Pedido: <strong>{order.number}</strong>
          <br />
          Código de rastreio: <strong>{order.tracking}</strong>
          <br />
          Endereço de retirada: <strong>{flatAddress(order.address)}</strong>
        </FormComponents.Order>
      )}
      <FormComponents.FormSubtitle>
        Para retirar o pedido, preencha os campos abaixo.
      </FormComponents.FormSubtitle>
      {withdrawOrderHasSucceeded ? <Confirmation /> : FormComponent}
      <Common.PoweredByImage />
    </Abbiamo.PageContainer>
  );
};

export default FormPage;
